<template>
  <!-- 弹窗公告管理 -->
  <div>
    <!-- 顶部搜索框 -->
    <div class="flex">
      <div class="t-flex">
        <span>显示终端</span>
        <el-select v-model="form" :placeholder="select[0].label">
          <el-option
            v-for="item in select"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <div>
          <!-- <el-button size="medium" @click="resetForm">重置</el-button> -->
          <el-button size="medium" type="primary" @click="query"
            >查询</el-button
          >
        </div>
      </div>
      <el-button size="medium" type="primary" @click="newNews"
        >添加公告</el-button
      >
    </div>
    <!-- 中间表格区 -->
    <div>
      <el-table :data="data" border>
        <el-table-column label="ID" prop="id" width="50"></el-table-column>
        <el-table-column
          label="公告类型"
          prop="announcementType"
          width="80"
        ></el-table-column>
        <el-table-column
          label="公告标题"
          prop="announcementTitle"
          width="150"
        ></el-table-column>
        <el-table-column
          label="公告内容"
          prop="contentTheAnnouncement"
          width="300"
        ></el-table-column>
        <el-table-column
          label="推送时间"
          prop="pushTime"
          width="200"
        ></el-table-column>
        <el-table-column label="创建时间" prop="creationTime"></el-table-column>
        <el-table-column
          label="操作时间"
          prop="operatingTime"
        ></el-table-column>
        <el-table-column
          label="状态"
          prop="store"
          width="100"
        ></el-table-column>
        <el-table-column label="操作" prop="id"></el-table-column>
        <el-table-column label="操作人" prop="operationOf"></el-table-column>
      </el-table>
    </div>
    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 30, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="23"
      >
      </el-pagination>
    </div>

    <!-- 新增弹窗公告管理 -->
    <el-dialog title="添加公告" :visible.sync="dialogFormVisible">
      <el-form :model="forms" label-width="80px">
        <el-form-item label="公告标题">
          <el-input
            v-model="forms.announcementTitle"
            placeholder="请输入公告标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="推送时间">
          <el-date-picker
            v-model="forms.pushTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="公告类型">
          <el-radio-group v-model="forms.announcementType">
            <el-radio :label="1">图片</el-radio>
            <el-radio :label="2">文本</el-radio>
          </el-radio-group>
        </el-form-item>
        <!-- 上传图片 -->
        <el-form-item label="公告图片" v-if="forms.announcementType == 1">
          <el-upload
            class="avatar-uploader"
            action="https://jsonplaceholder.typicode.com/posts/"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="forms.announcementPicture"
              :src="forms.announcementPicture"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 弹窗底部取消 确认 按钮 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false, // 新增弹窗公告状态
      form: "",
      select: [
        {
          value: 1,
          label: "全部",
        },
        {
          value: 2,
          label: "待审核",
        },
        {
          value: 3,
          label: "审核通过",
        },
        {
          value: 4,
          label: "审核未通过",
        },
        {
          value: 5,
          label: "发布中",
        },
        {
          value: 6,
          label: "已过期",
        },
        {
          value: 7,
          label: "已删除",
        },
      ],
      data: [
        {
          id: 1,
          announcementType: "文字",
          announcementTitle: "关于唛粒通（MTT）挂牌上",
          contentTheAnnouncement:
            "尊敬的用户： 企业数字通证拼车车PCC已挂牌上线并于2020年7",
          pushTime: "2020-07-09 09:30:00 ~ 2020-07-10 18:00:00",
          creationTime: "2020-07-09 09:10:15",
          operatingTime: "2021-12-27 12:17:47",
          store: 1,
          operationOf: "admin",
        },
        {
          id: 1,
          announcementType: "文字",
          announcementTitle: "关于唛粒通（MTT）挂牌上",
          contentTheAnnouncement:
            "尊敬的用户： 企业数字通证拼车车PCC已挂牌上线并于2020年7",
          pushTime: "2020-07-09 09:30:00 ~ 2020-07-10 18:00:00",
          creationTime: "2020-07-09 09:10:15",
          operatingTime: "2021-12-27 12:17:47",
          store: 1,
          operationOf: "刘承霞",
        },
      ],
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      forms: {
        announcementTitle: "",
        pushTime: "",
        announcementType: 1,
        announcementPicture: "",
      },
    };
  },
  created() {
    this.form = this.select[0].value;
  },
  methods: {
    // 查询
    query() {
      console.log(this.form);
    },
    //   重置
    resetForm() {
      this.form = "";
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    // 跳转新闻分类新增
    newNews() {
      console.log(123);
      this.dialogFormVisible = true;
    },
    // 确认
    confirm() {
      console.log(this.forms);
      this.dialogFormVisible = false;
    },
    // 点击上传图片
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    span {
      width: 80px;
    }
    div {
      margin: 0 10px;
    }
  }
}
.t-flex {
  width: 600px;
}
.pagination {
  padding: 60px 0 0 100px;
}
// 上传图片
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px dashed #8c939d;
}
.avatar {
  border: 1px dashed #8c939d;
  width: 120px;
  height: 120px;
  display: block;
}
</style>
